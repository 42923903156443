body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  scrollbar-color: unset !important;
}

*::-webkit-scrollbar {
  height: 0.5rem;
  width: 0.5rem;
}
*::-webkit-scrollbar-track {
  border-radius: 0;
}

*::-webkit-scrollbar-track,
*::-webkit-scrollbar-track:hover,
*::-webkit-scrollbar-track:active {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: hsl(0deg 0% 70%);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: hsl(0deg 0% 60%);
}

*::-webkit-scrollbar-thumb:active {
  background-color: hsl(0deg 0% 55%);
}